import { useState } from "react";
import "./App.css";

export default function App() {
  const [count, setCount] = useState(null);

  function onClick() {
    fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
    })
      .then((response) => response.text())
      .then(setCount);
  }

  return (
    <div className="App">
      <h1>App here</h1>
      <h2>VAR1: {process.env.REACT_APP_EXAMPLE1}</h2>
      <h2>VAR2: {process.env.EXAMPLE2}</h2>
    </div>
  );
}
